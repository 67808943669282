<template>
  <div class="government">
    <!--海报-->
    <div class="flex-box">
      <div class="banner" @click="scrollTo('scheme')">
        <img v-lazy="require('@/assets/government/banner.png')" class="banner-img" />
      </div>
    </div>
    <!--区域导航-->
    <div class="tabs" ref="tabs">
      <div class="contain">
        <div v-for="tab in tabs" :key="tab.id" @click="scrollTo(tab.id)" class="tab">
          {{ tab.name }}
        </div>
      </div>
    </div>
    <!--悬浮的区域导航-->
    <div class="tabs fixed" v-if="showTabHeader">
      <div class="contain">
        <div v-for="tab in tabs" :key="tab.id" @click="scrollTo(tab.id)" :class="['tab', tab.hover ? 'hover' : '']">
          {{ tab.name }}
        </div>
      </div>
    </div>
    <!--政企解决方案-->
    <div class="scheme" ref="scheme">
      <div class="title-box reveal">
        <div class="title1">康美心理·机关事业解决方案</div>
        <div class="title3">
          政府、事业单位专用的多级联动心理健康网络平台，基于公务人员心理的发展特点，通过对公务人员个体/整体<br />
          的身心健康状况、能力、自我、适应、人际关系、智力、职业生涯等方面进行全面评估
        </div>
      </div>
      <div class="scheme-contain">
        <div class="box reveal1">
          <img v-lazy="require('@/assets/government/scheme1.png')" class="box-img" />
          <div class="box-title">
            选人用才、为科学选拔人才提 供依据
          </div>
          <div class="box-intro">
            入职前进行专业性身心健康检测，可以从根源上杜绝心理不健康人员走进公务人员队伍。
          </div>
        </div>
        <div class="box reveal2">
          <img v-lazy="require('@/assets/government/scheme2.png')" class="box-img" />
          <div class="box-title">为岗位匹配，人尽其才提供依据</div>
          <div class="box-intro">
            了解个体的能力、人格和心理健康等心理特征，为岗位调配、奖惩提供一定的依据。
          </div>
        </div>
        <div class="box reveal3">
          <img v-lazy="require('@/assets/government/scheme3.png')" class="box-img" />
          <div class="box-title">保障安全管理，降低管理责任风险</div>
          <div class="box-intro">
            监测在岗人员心理状态，及时发现心理安全隐患，促进在岗公务人员心理健康，降低管理责任风险。
          </div>
        </div>
      </div>
    </div>
    <!--多场景应用-->
    <div class="use" ref="use">
      <div class="title-box reveal">
        <div class="title1">康美心理测评·机关事业应用场景</div>
        <div class="title2">满足机关事业单位不同阶段的人员心理测评需求</div>
      </div>
      <div class="use-box reveal box-bg1">
        <div class="box-main">
          <div class="box-title reveal1">
            <span class="title-num">01</span>
            单位周期性人员心理安全监测
          </div>
          <div class="box-intro reveal2">
            <i class="el-icon-success" style="color: #536cfe; margin-right: 10px"></i>专业临床心理量表，排查公务人员心理隐患
          </div>
          <div class="box-intro reveal2">
            <i class="el-icon-success" style="color: #536cfe; margin-right: 10px"></i>出具单位整体分析报告，心理危机分级预警
          </div>
          <div class="box-intro reveal2">
            <i class="el-icon-success" style="color: #536cfe; margin-right: 10px"></i>心理危机干预体系，疏解人员压力提升效率
          </div>
          <div class="reveal3">
            <div class="box-btn" @click="gotoLogin">申请试用</div>
          </div>
        </div>
      </div>
      <div class="use-box reveal box-bg2">
        <div class="box-main">
          <div class="box-title reveal1">
            <span class="title-num">02</span>
            单位人才选拔心理评估
          </div>
          <div class="box-intro reveal2">
            <i class="el-icon-success" style="color: #536cfe; margin-right: 10px"></i>人才甄选：鉴别心理特质，客观、全面、科学选拔人才
          </div>
          <div class="box-intro reveal2">
            <i class="el-icon-success" style="color: #536cfe; margin-right: 10px"></i>人员安置：预测安置人员与招聘岗位之间的契合度
          </div>
          <div class="box-intro reveal2">
            <i class="el-icon-success" style="color: #536cfe; margin-right: 10px"></i>招聘导向：对以后的人才选拔、人员培训提供指导
          </div>
          <div class="reveal3">
            <div class="box-btn" @click="gotoLogin">申请试用</div>
          </div>
        </div>
      </div>
      <div class="use-box reveal box-bg3">
        <div class="box-main">
          <div class="box-title reveal1">
            <span class="title-num">03</span>
            专家团队专业评估筛查
          </div>
          <div class="box-intro reveal2">
            <i class="el-icon-success" style="color: #536cfe; margin-right: 10px"></i>专家团队针对性为机关事业单位进行常态化评估筛查和分析，并提供专业心理健康服务
          </div>
          <div class="box-intro reveal2">
            <i class="el-icon-success" style="color: #536cfe; margin-right: 10px"></i>反馈整体/个人的不足和安全风险，针对性提升心理综合素质，降低管理者责任风险
          </div>
          <div class="box-intro reveal2">
            <i class="el-icon-success" style="color: #536cfe; margin-right: 10px"></i>识别不同成员间的异同，有助于更合理地选配人员，及时调整和优化团队人事配置
          </div>
          <div class="reveal3">
            <div class="box-btn" @click="gotoLogin">申请试用</div>
          </div>
        </div>
      </div>
    </div>
    <!--体验测评-->
    <div class="eval reveal" ref="eval">
      <div class="eval-box">
        <img v-lazy="require('@/assets/government/eval.png')" class="eval-img" />
      </div>
    </div>
    <!--权威测评量表-->
    <div class="projects" ref="projects">
      <div class="title-box reveal">
        <div class="title1">康美心理 综合性机关单位心理安全测评服务</div>
        <div class="title2">
          做专业的测评量表，看专家的分析报告，排查人事安全隐患
        </div>
      </div>
      <div class="project-box reveal">
        <div style="margin-bottom: 70px">
          <div class="project-line reveal"></div>
          <div class="project-title reveal1">
            国际权威量表，专业临床应用
          </div>
          <div class="project-intro reveal2">
            采用国际权威通用的量表，常模本土化，结合专家团队在国内的大量临床实践修订，多个专业心理安全监测量表，为机关事业单位评测个体身心健康作全方位指标精准监测分析，也是专业心理咨询机构、三甲医院常用的诊断标准。
          </div>
        </div>
        <div>
          <div class="project-line reveal"></div>
          <div class="project-title reveal1">
            随时随地监测，报告数据专业化、可视化
          </div>
          <div class="project-intro reveal2">
            全年实时关注心理健康状况，生成全年动态心理档案
            智能监测提醒，根据每次结果科学定制测评周期计划
          </div>
        </div>
      </div>
    </div>
    <!--评测报告解读-->
    <div class="report" ref="report">
      <div class="title-box reveal">
        <div class="title1">面向机关事业单位管理人员输出整体分析报告</div>
        <div class="title2">
          心理专家出具整体综合分析报告，提供分级危机预警名单
        </div>
      </div>
      <div class="report-contain">
        <div class="box reveal1">
          <div class="box-title title-bg1">
            <div style="background: #fafbff">身体健康情况评估</div>
          </div>
          <div class="box-intro">
            12大方面定位你的身体各项状况，精细化分析症状表现，快速筛查潜在健康风险。
          </div>
          <img v-lazy="require('@/assets/government/report-img1.png')" class="box-img1" />
        </div>
        <div class="box reveal2">
          <div class="box-title title-bg2">
            <div style="background: #fafbff">心理健康情况评估</div>
          </div>
          <div class="box-intro">
            从6大方面评估你的精神心理状态，详细解析对应的心理倾向，准确筛查潜在精神层面风险。
          </div>
          <img v-lazy="require('@/assets/government/report-img2.png')" class="box-img2" />
        </div>
        <div class="box reveal3">
          <div class="box-title title-bg3">
            <div style="background: #fafbff">性格情绪分类评估</div>
          </div>
          <div class="box-intro">
            从4个典型的人格特质因子，Dominance支配，Influence影响，Steady稳健，以及Compliance服从，衡量被测者的情绪反映“人格特征”。
          </div>
          <img v-lazy="require('@/assets/government/report-img3.png')" class="box-img3" />
        </div>
        <div class="box reveal4">
          <div class="box-img4">更多专业报告，持续输出</div>
        </div>
      </div>
    </div>
    <!--申请试用-->
    <div class="test" ref="test">
      <div class="title-box reveal">
        <div class="title1">申请免费试用</div>
        <div class="title2">填写信息，立即开通体验</div>
      </div>
      <img
          v-lazy="require('@/assets/org/test.jpg')"
          style="height:600px;width:1200px"
          class="revealImg"
          @click="gotoLogin"
        />
    </div>
    <!-- <div style="font-size:35px; width:950px; height:50px; text-align:center; font-weight:bold;" >浙ICP备2022026803号</div> -->

  </div>
</template>

<script>
import scrollReveal from "scrollreveal";
export default {
  name: "government",
  data() {
    return {
      tabs: [
        {
          name: "机关事业解决方案",
          id: "scheme",
          hover: false,
        },
        {
          name: "多场景应用",
          id: "use",
          hover: false,
        },
        {
          name: "体验测评",
          id: "eval",
          hover: false,
        },
        {
          name: "权威测评量表",
          id: "projects",
          hover: false,
        },
        {
          name: "测评报告解读",
          id: "report",
          hover: false,
        },
        {
          name: "申请试用",
          id: "test",
          hover: false,
        },
      ],
      showTabHeader: false,
      scrollReveal: new scrollReveal(),
    };
  },
  mounted() {
    this.getReveal();
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll: function () {
      this.$nextTick(() => {
        let top = this.$refs.tabs.getBoundingClientRect().top;
        this.showTabHeader = top <= 0 ? true : false;
        if (top <= 0) {
          this.tabs.forEach((tab) => {
            let tabData = this.$refs[tab.id].getBoundingClientRect();
            if (
              Math.floor(tabData.top) <= 0 &&
              Math.floor(tabData.height + tabData.top) > 0
            ) {
              tab["hover"] = true;
            } else {
              tab["hover"] = false;
            }
          });
        }
      });
    },
    scrollTo: function (name) {
      this.$nextTick(() => {
        this.$refs[name].scrollIntoView({ block: "start", behavior: "smooth" });
      });
    },
    gotoLogin() {
      this.$router.push("/login");
    },
    getReveal() {
      let animation = {
        duration: 1000,
        reset: false,
        mobile: false,
        opacity: 0,
        easing: "ease-in-out",
        enter: "bottom",
        distance: "40px",
      };
      let setReveal = (className, delay) => {
        let revealAnimation = JSON.parse(JSON.stringify(animation));
        revealAnimation["delay"] = delay;
        this.scrollReveal.reveal(className, revealAnimation);
      };
      setReveal(".reveal", 0);
      setReveal(".revealImg", 500);
      setReveal(".revealContent", 800);
      setReveal(".reveal1", 200);
      setReveal(".reveal2", 500);
      setReveal(".reveal3", 800);
      setReveal(".reveal4", 1100);
    },
  },
};
</script>
<style scoped lang="scss">
.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}

.government {
  width: 100%;
  background: #fff;

  .title-box {
    @include flex($direction: column, $justify: center);
    text-align: center;
    color: $title-color;
    .title1 {
      font-size: 48px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .title2 {
      font-size: 24px;
      line-height: 33px;
    }
    .title3 {
      font-size: 16px;
      line-height: 30px;
    }
  }

  .banner {
    @include flex($direction: column);
    width: 100%;
    height: 500px;
    overflow: hidden;
    background-image: url('~@/assets/government/banner-bg.png');
    background-size: 100% 500px;
    background-repeat: no-repeat;
    background-color: #fff;

    .banner-img {
      width: 1920px;
      height: 500px;
    }
  }

  .tabs {
    width: 100%;
    height: 60px;
    background: #f0f1f7;
    @include flex($justify: center);
    .contain {
      width: $width;
      height: 100%;
      @include flex($justify: space-around);
      .tab {
        font-size: 16px;
        color: #414246;
      }
    }
  }

  .fixed {
    background-color: #f5f6f8;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    height: 60px;
    .tab {
      position: relative;
      padding: 20px 0;
    }
    .tab::after {
      position: absolute;
      content: '';
      bottom: 1px;
      left: 50%;
      width: 0;
      height: 3px;
      border-radius: 30px;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      background-color: $color;
      -webkit-transition: all 0.25s ease-in-out;
      -o-transition: all 0.25s ease-in-out;
      transition: all 0.25s ease-in-out;
    }
    .tab.hover {
      color: $color;
    }
    .tab:hover {
      color: $color;
    }
    .tab:hover::after {
      width: 100%;
    }
  }

  .scheme {
    @include flex($direction: column, $justify: center);
    padding-top: 100px;
    .title-box {
      margin-bottom: 60px;
    }
    .scheme-contain {
      width: $width;
      @include flex($justify: space-between);
      .box {
        width: 374px;
        height: 430px;
        border-radius: 4px;
        overflow: hidden;
        background: #fafbff;
        padding: 20px 28px;
        .box-img {
          width: 318px;
          height: 150px;
          margin-bottom: 36px;
        }
        .box-title {
          color: $title-color;
          font-size: 22px;
          line-height: 30px;
          margin-bottom: 20px;
        }
        .box-intro {
          color: $intro-color;
          font-size: 18px;
          line-height: 30px;
        }
      }
      .box:hover {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }

  .use {
    @include flex($direction: column, $justify: center);
    padding-top: 100px;
    .title-box {
      margin-bottom: 60px;
    }
    .use-box {
      width: $width;
      height: 510px;
      background-size: $width 510px;
      background-repeat: no-repeat;
      .box-main {
        background: rgba(255, 255, 255, 0.83);
        padding: 63px 0 0 72px;

        .title-num {
          font-size: 90px;
          font-family: Arial;
          line-height: 30px;
          color: $title-color;
          font-style: Italic;
        }
        .box-title {
          font-size: 40px;
          color: $title-color;
          line-height: 30px;
          margin-bottom: 48px;
        }
        .box-intro {
          font-size: 22px;
          color: #666;
          line-height: 36px;
          margin-bottom: 20px;
        }
        .box-btn {
          width: 200px;
          height: 60px;
          line-height: 60px;
          background: #536cfe;
          border-radius: 5px;
          text-align: center;
          color: #fff;
          font-size: 24px;
          margin-top: 40px;
          transition: all 0.2s;
        }
        .box-btn:hover {
          transform: translateY(10px);
        }
      }
    }
    .box-bg1 {
      background: url('~@/assets/government/use-bg1.png');
      padding: 24px 0 0 54px;
      .box-main {
        width: 747px;
        height: 458px;
      }
    }
    .box-bg2 {
      background: url('~@/assets/government/use-bg2.png');
      padding: 24px 0 0 404px;
      .box-main {
        width: 747px;
        height: 458px;
      }
    }
    .box-bg3 {
      background: url('~@/assets/government/use-bg3.png');
      padding: 24px 0 0 70px;
      .box-main {
        width: 951px;
        height: 458px;
      }
    }
    .use-box:not(:last-of-type) {
      margin-bottom: 40px;
    }
  }

  .eval {
    padding-top: 100px;
    @include flex($direction: column, $justify: center);
    .eval-box {
      @include flex($justify: center);
      width: 100%;
      background: #536cfe;
      overflow: hidden;

      .eval-img {
        width: 1920px;
        height: 300px;
      }
    }
  }

  .projects {
    padding-top: 100px;
    @include flex($direction: column, $justify: center);
    background: #f7f7f7;
    .title-box {
      margin-bottom: 60px;
    }
    .project-box {
      width: $width;
      height: 520px;
      padding: 50px 72px 0 92px;
      background: url('~@/assets/government/project-bg.png');
      background-size: $width 520px;
      .project-line {
        width: 80px;
        height: 4px;
        background: #ffffff;
        margin-bottom: 30px;
      }
      .project-title {
        font-size: 40px;
        color: #fff;
        line-height: 30px;
        font-weight: 800;
        margin-bottom: 30px;
      }
      .project-intro {
        font-size: 20px;
        color: #fff;
        line-height: 30px;
      }
    }
  }

  .report {
    @include flex($direction: column, $justify: center);
    background: #f7f7f7;
    padding-top: 100px;
    padding-bottom: 90px;
    .title-box {
      margin-bottom: 60px;
    }
    .report-contain {
      width: $width;
      @include flex($justify: space-between);
      .box {
        @include flex($direction: column);
        width: 280px;
        height: 474px;
        border-radius: 4px;
        background: #fff;
        padding: 15px 20px;
        .box-title {
          @include flex($direction: column, $justify: center);
          width: 100%;
          height: 117px;
          font-size: 30px;
          color: #596df8;
          font-weight: bold;
          background-color: #fff;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 99px 101px !important;
          padding-top: 80px;
        }
        .title-bg1 {
          background-image: url('~@/assets/government/title-bg1.png');
        }
        .title-bg2 {
          background-image: url('~@/assets/government/title-bg2.png');
        }
        .title-bg3 {
          background-image: url('~@/assets/government/title-bg3.png');
        }
        .box-intro {
          font-size: 16px;
          color: $intro-color;
          line-height: 26px;
          margin-top: 24px;
        }
        .box-img1 {
          width: 170px;
          height: 163px;
          margin-top: 45px;
        }
        .box-img2 {
          width: 181px;
          height: 160px;
          margin-top: 52px;
        }
        .box-img3 {
          width: 147px;
          height: 170px;
        }
        .box-img4 {
          color: #929292;
          font-size: 20px;
          padding-top: 205px;
          line-height: 26px;
          text-align: center;
        }
      }
      .box:hover {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      }
    }
  }

  .test {
    @include flex($direction: column, $justify: center);
    padding-top: 100px;
    .title-box {
      margin-bottom: 40px;
    }
    .test-img {
      width: $width;
      height: 600px;
    }

  }
}
</style>
